export interface FeatureFlag {
  label: string;
  state: boolean;
}

export type FeatureFlagId =
  | "similarClustersEnabled"
  | "similarClustersAlt"
  | "enableNewCompRec"
  | "enablePriceEstimates"
  | "enableHeatmapSettings"
  | "autoFilters"
  | "formsReviewDebug"
  | "deleteCoreCustomCluster"
  | "allowControllingMapLayers"
  | "enableKBBExport";

export const FeatureFlagDefinitions: Record<FeatureFlagId, FeatureFlag> = {
  similarClustersEnabled: {
    label: "Enable Similar Clusters tab",
    state: false,
  },
  similarClustersAlt: {
    label: "Alternative Similar Clusters tab",
    state: false,
  },
  enableNewCompRec: {
    label: "Enable new comp recommendation page",
    state: false,
  },
  enablePriceEstimates: {
    label: "Enable Price Estimates tab",
    state: false,
  },
  enableHeatmapSettings: {
    label: "Enable heatmap settings panel",
    state: false,
  },
  autoFilters: {
    label: "Automatically fill in filters based on available data",
    state: false,
  },
  deleteCoreCustomCluster: {
    label: "Enable deleting core custom clusters",
    state: false,
  },
  allowControllingMapLayers: {
    label: "Enable controls on all map layers of the market definition map",
    state: false,
  },
  formsReviewDebug: {
    label: "Enable form review debug panel on Market Analysis page",
    state: false,
  },
  enableKBBExport: {
    label: "Enable KBB export",
    state: false,
  },
};

export type FeatureFlagGroupId =
  | "generalSettings"
  | "marketDefinitionFeatures"
  | "developmentFeatures";

export interface FeatureFlagGroup {
  label: string;
  featureFlagIds: FeatureFlagId[];
}

export const FeatureFlagGroupDefinitions = {
  generalSettings: {
    label: "General Settings",
    featureFlagIds: ["enableNewCompRec", "enableKBBExport"],
  },
  marketDefinitionFeatures: {
    label: "Market Definition Features",
    featureFlagIds: [
      "enablePriceEstimates",
      "similarClustersAlt",
      "similarClustersEnabled",
    ],
  },
  developmentFeatures: {
    label: "Development features",
    featureFlagIds: [
      "allowControllingMapLayers",
      "autoFilters",
      "deleteCoreCustomCluster",
      "enableHeatmapSettings",
      "formsReviewDebug",
    ],
  },
} as const;
