import { atom, useAtomValue } from "jotai";
import { atomFamily, atomWithStorage } from "jotai/utils";

import {
  FeatureFlagDefinitions,
  FeatureFlagId,
} from "./featureFlags/featureFlagList";

function featureFlagLocalStorageKey(featureFlagId: FeatureFlagId) {
  return `featureFlag.${featureFlagId}`;
}

const featureFlagFamily = atomFamily((featureFlagId: FeatureFlagId) =>
  atomWithStorage<boolean>(
    featureFlagLocalStorageKey(featureFlagId),
    FeatureFlagDefinitions[featureFlagId].state,
    undefined,
    {
      getOnInit: false,
    },
  ),
);

export const useFeatureFlagAtomValue = (featureFlagId: FeatureFlagId) => {
  const atom = featureFlagFamily(featureFlagId);
  return useAtomValue(atom);
};

export const setFeatureFlagStateAtom = atom(
  null,
  (_get, set, featureFlagId: FeatureFlagId, newState: boolean) => {
    const atom = featureFlagFamily(featureFlagId);
    set(atom, newState);
  },
);
